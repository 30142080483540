@import url('https://fonts.googleapis.com/css2?family=Artifika&family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply font-spartan
    }
    h1 {
        @apply text-6xl font-semibold
    }
    h2 {
        @apply text-4xl font-medium
    }
    h3 {
        @apply text-3xl font-normal
    }
    h4 {
        @apply text-2xl font-normal
    }
    h5 {
        @apply text-base md:text-lg font-extralight
    }
    p {
        @apply text-xl font-normal
    }
    a {
        @apply font-light text-lg
    }
}